import React, { useState } from "react";
import { Link } from "gatsby";
import useProducts from "../hooks/useProducts";
import { getProductsFromCategory } from "../utils/helperFunctions";
import { debounce } from "lodash";
import { AnimatePresence, motion } from "framer-motion";
import { useIsSmallScreen } from "../hooks/useWindowSizes";
import SearchButtonSvg from "../images/svgs/SearchButtonSvg";
import "./hamburgers.min.css";

const navVariants = {
  initial: {
    opacity: 0,
    x: "100%",
  },
  visible: {
    opacity: 1,
    x: "0",
    transition: {
      duration: 0.3,
      ease: "easeOut",
    },
  },
  hidden: {
    opacity: 0,
    x: "100%",

    transition: {
      duration: 0.3,
      ease: "easeOut",
    },
  },
};

const subMenuLiVariants = {
  initial: {
    opacity: 0,
    x: -20,
  },

  animate: {
    "--gradientLeft": "#C5C5C6",
    "--gradientRight": "#1C1B20",
    opacity: 1,
    x: 0,
    transition: { duration: 0.3 },
  },
  exit: {
    opacity: 0,
  },
  hover: {
    "--gradientLeft": "#1C1B20",
    "--gradientRight": "#C5C5C6",
    opacity: 1,
    x: 0,
    transition: { duration: 0.3 },
  },
};

const ulVariants = {
  initial: {
    opacity: 0,
    y: 20,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.1,
      ease: "easeOut",
      when: "beforeChildren",
      staggerChildren: 0.02,
    },
  },
  exit: {
    opacity: 0,
    y: 20,
    transition: {
      duration: 0.3,
      ease: "easeOut",
      staggerChildren: 0.02,
      staggerDirection: 1,
    },
  },
};

const SubNav = ({
  products,
  showSubMenu,
  handleMouseEnterSubMenu,
  debouncedHandleMouseLeave,
}) => {
  return (
    <AnimatePresence>
      {showSubMenu && (
        <motion.ul
          key="ul"
          className="subMenu"
          onMouseEnter={handleMouseEnterSubMenu}
          onMouseLeave={debouncedHandleMouseLeave}
          variants={ulVariants}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          {products.map((product) => (
            <motion.li key={product.slug} variants={subMenuLiVariants}>
              <Link to={`/product/${product.slug}`} title={product.title}>
                {product.title}
              </Link>
            </motion.li>
          ))}
        </motion.ul>
      )}
    </AnimatePresence>
  );
};

const SubMenuLink = ({
  title,
  dataText,
  to,
  subMenu,
  setNavOpen,
  isSmallScreen,
}) => {
  const [showSubMenu, setShowSubMenu] = useState(false);

  const debouncedHandleMouseEnter = debounce(() => {
    setShowSubMenu(true);
  }, 100);

  const debouncedHandleMouseLeave = debounce(() => {
    debouncedHandleMouseEnter.cancel();
    setShowSubMenu(false);
  }, 50);

  const handleMouseEnterSubMenu = () => {
    debouncedHandleMouseLeave.cancel();
  };
  return (
    <li className={`hasSubMenu ${showSubMenu ? "expanded" : ""}`}>
      <Link
        to={to}
        title={title}
        activeClassName="active"
        className="parentLink"
        data-text={dataText}
        onMouseEnter={() => {
          if (!isSmallScreen) debouncedHandleMouseEnter();
        }}
        onMouseLeave={() => {
          if (!isSmallScreen) debouncedHandleMouseLeave();
        }}
        onClick={() => setNavOpen(false)}
      >
        {dataText}
      </Link>
      <SubNav
        products={subMenu}
        showSubMenu={showSubMenu}
        handleMouseEnterSubMenu={handleMouseEnterSubMenu}
        debouncedHandleMouseLeave={debouncedHandleMouseLeave}
      />
    </li>
  );
};

export default function Nav() {
  const products = useProducts();

  const [navOpen, setNavOpen] = useState(false);
  const filmCameras = getProductsFromCategory("film-cameras", products);
  const digitalCameras = getProductsFromCategory("digital-cameras", products);
  const spherical = getProductsFromCategory("spherical", products);
  const speciality = getProductsFromCategory("speciality", products);
  const zooms = getProductsFromCategory("zooms", products);
  const _16mm = getProductsFromCategory("16mm", products);

  const isSmallScreen = useIsSmallScreen(1100);

  return (
    <nav>
      {isSmallScreen && (
        <div className="burger">
          <button
            className={`hamburger hamburger--slider ${
              navOpen ? "is-active" : ""
            }`}
            type="button"
            onClick={() => setNavOpen(!navOpen)}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner">Toggle Nav</span>
            </span>
          </button>
        </div>
      )}

      <motion.div
        className={`ulHolder ${isSmallScreen ? "smallScreenNav" : ""}`}
        variants={navVariants}
        initial="initial"
        animate={`${
          isSmallScreen ? (navOpen ? "visible" : "hidden") : "visible"
        }`}
      >
        <ul className={`mainMenu ${navOpen ? "open" : ""} `}>
          <SubMenuLink
            to="/category/digital-cameras"
            title="Digital Cameras"
            dataText="Digital Cameras"
            subMenu={digitalCameras}
            isSmallScreen={isSmallScreen}
            setNavOpen={setNavOpen}
          />
          <SubMenuLink
            to="/category/film-cameras"
            title="Film Cameras"
            dataText="Film Cameras"
            subMenu={filmCameras}
            isSmallScreen={isSmallScreen}
            setNavOpen={setNavOpen}
          />
          <SubMenuLink
            to="/category/spherical"
            title="Spherical lenses"
            dataText="Spherical"
            subMenu={spherical}
            isSmallScreen={isSmallScreen}
            setNavOpen={setNavOpen}
          />
          <SubMenuLink
            to="/category/zooms"
            title="Zooms lenses"
            dataText="Zooms"
            subMenu={zooms}
            isSmallScreen={isSmallScreen}
            setNavOpen={setNavOpen}
          />
          <SubMenuLink
            to="/category/16mm"
            title="16mm"
            dataText="16mm"
            subMenu={_16mm}
            isSmallScreen={isSmallScreen}
            setNavOpen={setNavOpen}
          />
          <SubMenuLink
            to="/category/speciality"
            title="Speciality lenses"
            dataText="Speciality"
            subMenu={speciality}
            isSmallScreen={isSmallScreen}
            setNavOpen={setNavOpen}
          />
          <li>
            <Link
              to="/full-inventory"
              title="Our full kit list"
              activeClassName="active"
              data-text="Full Inventory"
              onClick={() => setNavOpen(false)}
            >
              Full Inventory
            </Link>
          </li>
          <li className="contact">
            <Link
              to="/contact"
              title="Contact MCX Films"
              activeClassName="active"
              data-text="Contact"
              onClick={() => setNavOpen(false)}
            >
              Contact
            </Link>
          </li>
          <li className="search">
            <Link
              to="/search"
              title="Contact MCX Films"
              activeClassName="active"
              data-text="Search"
              onClick={() => setNavOpen(false)}
            >
              <span>Search</span>
              <SearchButtonSvg />
            </Link>
          </li>
        </ul>
      </motion.div>
    </nav>
  );
}
