import React from 'react';

import '../styles/normalize.css';
import '../styles/styles.scss';
import { AnimatePresence, motion } from 'framer-motion';
import Header from './Header';
import Footer from './Footer';

const mainVariants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      duration: 0.3,
      type: 'tween',
      ease: 'easeInOut',
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.3,
      type: 'tween',
      ease: 'easeInOut',
    },
  },
};

export default function Layout({ children, location }) {
  return (
    <>
      <Header />
      <AnimatePresence mode="wait">
        {!location.pathname.startsWith('/contact') && (
          <motion.main key={location.pathname} variants={mainVariants} initial="initial" animate="animate" exit="exit">
            {children}
          </motion.main>
        )}
      </AnimatePresence>
      <Footer isContact={location.pathname.startsWith('/contact')} />
    </>
  );
}
