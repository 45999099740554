import React from 'react';
import Nav from './Nav';
import McxLogo from '../images/svgs/McxLogo';

export default function Header() {
  return (
    <header>
      <h1>MCX Films</h1>
      <McxLogo />
      <Nav />
    </header>
  );
}
