export const getProductsFromCategory = (category, products, includeUnlinkedProducts = false) => {
  return products.filter((product) => {
    const thisProductsCategories = product.categories.nodes;
    const include = includeUnlinkedProducts ? true : product.linkProduct.linkProduct;

    return thisProductsCategories.some((cat) => cat.slug === category) && include;
  });
};

export const getProductsFromTag = (tagSlug, products, includeUnlinkedProducts = false) => {
  return products.filter((product) => {
    const thisProductsTags = product.tags?.nodes;
    if (!thisProductsTags) return false;
    const include = includeUnlinkedProducts ? true : product.linkProduct.linkProduct;

    return thisProductsTags.some((cat) => cat.slug === tagSlug) && include;
  });
};

export const getSubCategoriesOfCategory = (categorySlug, categories, returnEmpty = false) => {
  const parentId = categories.find((cat) => cat.slug === categorySlug).id;

  return categories
    .filter((cat) => cat.parentId === parentId)
    .filter((cat) => {
      return cat.count > 0 || returnEmpty;
    });
};

export const getCategoryFromId = (id, categories) => {
  return categories.find((cat) => cat.id === id);
};
