import React from 'react';

export const Phone = () => (
  <svg width="20" height="29" viewBox="0 0 20 29" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2.54269 0H16.9782C17.5252 0 18.0497 0.218239 18.4365 0.606707C18.8232 0.995176 19.0405 1.52205 19.0405 2.07143V26.9286C19.0405 27.4779 18.8232 28.0048 18.4365 28.3933C18.0497 28.7818 17.5252 29 16.9782 29H2.54269C1.99576 29 1.47122 28.7818 1.08448 28.3933C0.697738 28.0048 0.480469 27.4779 0.480469 26.9286V2.07143C0.480469 1.52205 0.697738 0.995176 1.08448 0.606707C1.47122 0.218239 1.99576 0 2.54269 0ZM2.54269 2.07143V22.7857H16.9782V2.07143H2.54269ZM9.18043 27.8404C10.1414 27.8404 10.9204 27.0613 10.9204 26.1004C10.9204 25.1394 10.1414 24.3604 9.18043 24.3604C8.21945 24.3604 7.44043 25.1394 7.44043 26.1004C7.44043 27.0613 8.21945 27.8404 9.18043 27.8404Z"
      fill="currentColor"
    />
  </svg>
);

export const Email = () => (
  <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.75 0H1.25C0.918479 0 0.600537 0.131696 0.366116 0.366116C0.131696 0.600537 0 0.918479 0 1.25V13.75C0 14.0815 0.131696 14.3995 0.366116 14.6339C0.600537 14.8683 0.918479 15 1.25 15H18.75C19.0815 15 19.3995 14.8683 19.6339 14.6339C19.8683 14.3995 20 14.0815 20 13.75V1.25C20 0.918479 19.8683 0.600537 19.6339 0.366116C19.3995 0.131696 19.0815 0 18.75 0ZM17.7875 13.75H2.2875L7.9 8.34375L7 7.475L1.25 13.025V2.2L9.01875 9.93125C9.25295 10.1641 9.56977 10.2947 9.9 10.2947C10.2302 10.2947 10.547 10.1641 10.7812 9.93125L18.75 2.00625V12.9437L12.8813 7.475L12 8.35625L17.7875 13.75ZM2.06875 1.25H17.7375L9.9 9.04375L2.06875 1.25Z"
      fill="currentColor"
    />
  </svg>
);

export const Instagram = () => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.49745 6.33198C7.75305 6.33198 6.32949 7.75557 6.32949 9.5C6.32949 11.2444 7.75305 12.668 9.49745 12.668C11.2418 12.668 12.6654 11.2444 12.6654 9.5C12.6654 7.75557 11.2418 6.33198 9.49745 6.33198ZM18.9989 9.5C18.9989 8.18811 19.0108 6.88811 18.9371 5.5786C18.8635 4.05757 18.5165 2.70766 17.4043 1.59541C16.2897 0.480781 14.9421 0.136173 13.4211 0.0624986C12.1093 -0.0111762 10.8093 0.000706907 9.49982 0.000706907C8.18796 0.000706907 6.88798 -0.0111762 5.5785 0.0624986C4.0575 0.136173 2.70761 0.483158 1.59538 1.59541C0.480772 2.71004 0.136171 4.05757 0.0624975 5.5786C-0.0111759 6.89049 0.000706894 8.19049 0.000706894 9.5C0.000706894 10.8095 -0.0111759 12.1119 0.0624975 13.4214C0.136171 14.9424 0.483149 16.2923 1.59538 17.4046C2.70999 18.5192 4.0575 18.8638 5.5785 18.9375C6.89036 19.0112 8.19034 18.9993 9.49982 18.9993C10.8117 18.9993 12.1117 19.0112 13.4211 18.9375C14.9421 18.8638 16.292 18.5168 17.4043 17.4046C18.5189 16.29 18.8635 14.9424 18.9371 13.4214C19.0132 12.1119 18.9989 10.8119 18.9989 9.5ZM9.49745 14.3744C6.80005 14.3744 4.62312 12.1974 4.62312 9.5C4.62312 6.80255 6.80005 4.62558 9.49745 4.62558C12.1948 4.62558 14.3718 6.80255 14.3718 9.5C14.3718 12.1974 12.1948 14.3744 9.49745 14.3744ZM14.5714 5.56434C13.9416 5.56434 13.433 5.05575 13.433 4.42595C13.433 3.79615 13.9416 3.28755 14.5714 3.28755C15.2012 3.28755 15.7098 3.79615 15.7098 4.42595C15.71 4.5755 15.6806 4.72361 15.6235 4.86181C15.5664 5.00001 15.4825 5.12558 15.3768 5.23133C15.271 5.33708 15.1455 5.42093 15.0073 5.47807C14.8691 5.53521 14.721 5.56453 14.5714 5.56434Z"
      fill="currentColor"
    />
  </svg>
);

export const Map = () => (
  <svg width="22" height="28" viewBox="0 0 22 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11 0C4.92489 0 0 4.72549 0 10.5547C0 12.5041 0.397669 14.5176 1.53885 15.9688L11 28L20.4611 15.9688C21.4977 14.6507 22 12.3204 22 10.5547C22 4.72549 17.0751 0 11 0ZM11 6.11303C13.5562 6.11303 15.6291 8.10199 15.6291 10.5547C15.6291 13.0074 13.5562 14.9963 11 14.9963C8.44382 14.9963 6.37097 13.0074 6.37097 10.5547C6.37097 8.10199 8.44382 6.11303 11 6.11303Z"
      fill="currentColor"
    />
  </svg>
);
