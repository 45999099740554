import React from 'react';
import { Phone, Map, Email, Instagram } from '../images/svgs/footerSvgs';
import McxLogo from '../images/svgs/McxLogo';
import ContactForm from '../components/ContactForm';

export default function Footer({ isContact }) {
  //get the current year
  const year = new Date().getFullYear();
  return (
    <footer className={`${isContact ? 'contactFooter' : ''}`}>
      <div className="footerInner">
        <McxLogo />
        <h5>MCX</h5>
        <div className="contactDetails">
          <ul>
            <li>
              <Phone />
              <a href="tel:00442077297474" title="Call MCX Films">
                +44 (0) 207 729 7474
              </a>
            </li>
            <li>
              <Email />
              <a href="mailto:info@mcxfilms.com" title="Email MCX Films">
                info@mcxfilms.com
              </a>
            </li>
            <li>
              <Instagram />
              <a href="https://www.instagram.com/mcx_films" title="MCX on Instagram">
                @mcx_films
              </a>
            </li>
          </ul>
          <ul className="address">
            <li>
              <Map />
              <a href="https://g.page/mcx-films?share" title="MCX Films on Google Maps">
                <p>374-375 Geffrye Street</p>
                <p>London</p>
                <p>E2 8HZ</p>
              </a>
            </li>
          </ul>
        </div>
      </div>
      {isContact && <ContactForm />}
      <div className="bottom">
        <div>Copyright {year} MCX Films</div>
        <div>
          <a href="mailto:ben@lookupstudios.co.uk" title="Email Look Up! web design">
            Site by Look Up!
          </a>
        </div>
      </div>
    </footer>
  );
}
