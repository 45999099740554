import { useStaticQuery, graphql } from 'gatsby';

export default function useProducts() {
  const data = useStaticQuery(graphql`
    query ProductsQuery {
      allWpProduct(sort: { order: ASC, fields: title }) {
        nodes {
          categories {
            nodes {
              slug
              name
            }
          }
          tags {
            nodes {
              slug
              name
            }
          }
          title
          slug
          menuOrder
          linkProduct {
            linkProduct
          }
        }
      }
    }
  `);

  return data.allWpProduct.nodes;
}
