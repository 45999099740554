import React, { useState } from 'react';
import { contactFormHolder } from './ContactForm.module.scss';

import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import { useFormik } from 'formik';
import * as yup from 'yup';

import { motion, AnimatePresence } from 'framer-motion';

const resultVariants = {
  initial: {
    opacity: 0,
    y: 25,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.25,
      type: 'tween',
    },
  },
  exit: {
    opacity: 0,
    y: -25,
    transition: {
      duration: 0.25,
      type: 'tween',
    },
  },
};

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&');
}

const validationSchema = yup.object({
  name: yup.string('Enter your name').required('Your name is required'),
  emailAddress: yup.string('Enter your email').email('Enter a valid email').required('Email is required'),
  subject: yup.string('Enter a subject'),
  enquiry: yup.string('Enter your enquiry'),
});

const theme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#ddd',
    },
    secondary: {
      main: '#000',
      contrastText: '#F3C208',
    },
  },
  typography: {
    fontFamily: ['Clash Grotesk'].join(','),
    fontSize: 22,
  },
});

export default function ContactForm() {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      emailAddress: '',
      subject: '',
      enquiry: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setLoading(true);

      const message = `
      Name - ${values.name}
      Email - ${values.emailAddress}
      Subject - ${values.subject}
      Enquiry - ${values.enquiry}
      `;

      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({
          'form-name': 'ContactPageForm',
          enquiry: message,
        }),
      })
        .then(() => {
          setLoading(false);
          setResult('success');
        })
        .catch((error) => {
          setLoading(false);
          setResult('error');
          console.log(error);
        });
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <AnimatePresence mode="wait">
        {!loading && !result && (
          <motion.form
            variants={resultVariants}
            initial="initial"
            exit="exit"
            animate="animate"
            key="form"
            id="ContactPageForm"
            className={`${contactFormHolder} contactForm`}
            onSubmit={formik.handleSubmit}
            data-netlify="true">
            <input type="hidden" name="form-name" value="ContactPageForm" />
            <div className="nameAndEmail">
              <TextField
                id="name"
                label="Your name"
                variant="standard"
                className="name"
                required={true}
                fullWidth={true}
                value={formik.values.name}
                onChange={formik.handleChange}
                error={formik.touched.name && Boolean(formik.errors.name)}
                helperText={formik.touched.name && formik.errors.name}
              />
              <TextField
                id="emailAddress"
                label="Email address"
                variant="standard"
                className="emailAddress"
                fullWidth={true}
                required={true}
                value={formik.values.emailAddress}
                onChange={formik.handleChange}
                error={formik.touched.emailAddress && Boolean(formik.errors.emailAddress)}
                helperText={formik.touched.emailAddress && formik.errors.emailAddress}
              />
            </div>
            <TextField
              id="subject"
              label="Subject"
              variant="standard"
              className="subject"
              fullWidth={true}
              value={formik.values.subject}
              onChange={formik.handleChange}
              error={formik.touched.subject && Boolean(formik.errors.subject)}
              helperText={formik.touched.subject && formik.errors.subject}
            />
            <TextField
              id="enquiry"
              name="enquiry"
              label="Message"
              fullWidth={true}
              multiline
              rows={8}
              className="enquiry"
              value={formik.values.enquiry}
              onChange={formik.handleChange}
              error={formik.touched.enquiry && Boolean(formik.errors.enquiry)}
              helperText={formik.touched.enquiry && formik.errors.enquiry}
            />
            <Button type="submit" variant="contained" className="submitButton" color="secondary" size="large">
              Send
            </Button>
          </motion.form>
        )}
        {loading && (
          <div className="result">
            <p>Loading...</p>
          </div>
        )}
        {result === 'success' && (
          <motion.div className="success result" variants={resultVariants} initial="initial" exit="exit" animate="animate" key="success">
            <p>Success! Thank you for your enquiry.</p>
            <Button
              variant="outlined"
              onClick={() => {
                setResult(false);
                //reset form
                formik.resetForm();
              }}>
              Send another enquiry
            </Button>
          </motion.div>
        )}
        {result === 'error' && (
          <motion.div className="error result" variants={resultVariants} initial="initial" exit="exit" animate="animate" key="error">
            <p>Sorry, there's been a problem.</p>
            <Button
              variant="outlined"
              onClick={() => {
                setResult(false);
                //reset form
                formik.resetForm();
              }}>
              Try again
            </Button>
          </motion.div>
        )}
      </AnimatePresence>
    </ThemeProvider>
  );
}
