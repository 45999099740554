import { Link } from 'gatsby';
import React from 'react';

export const McxLogo = () => (
  <Link to="/" title="MCX Homepage" className="logoLink">
    <svg width="138" height="39" viewBox="0 0 138 39" fill="none" xmlns="http://www.w3.org/2000/svg" className="footerLogo">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M104.109 17.9551L98.0214 1.31445H111.672L114.571 10.4278H115.81L123.162 1.31445H137.999L121.792 18.2777L121.291 19.4874L127.563 36.8002H113.359L110.803 28.2245H109.511L101.948 36.8002H86.7422L103.74 19.0035L104.109 17.9551Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M77.804 22.7491L90.0744 26.5225C90.0744 26.5225 84.7596 38.1143 68.0311 38.1143C51.3026 38.1143 51.1729 25.7318 51.1729 22.8034C51.1729 12.4368 59.9193 0 76.9624 0C94.0055 0 93.9021 12.8676 93.9021 12.8676L79.2699 14.5508C79.2699 14.5508 77.9023 12.0532 74.2749 12.0532C70.3218 12.0532 65.1807 15.0817 65.1807 20.8488C65.1807 26.2359 70.4892 26.1153 71.1801 26.1153C75.6987 26.1153 77.804 22.7491 77.804 22.7491Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 36.7463L11.4853 1.31439H28.401L26.5275 17.9819H27.695L36.8453 1.34128H55.2L43.769 36.7732L30.3559 36.7195L34.1301 24.8909H32.9354L27.0977 36.8001H17.0786L18.409 24.8909H17.2144L13.3588 36.7195L0 36.7463Z"
        fill="currentColor"
      />
    </svg>
  </Link>
);

export default McxLogo;
